<template>
  <div class="shareDetail">
    <div class="titleHead">
      <span></span>
      <h4>订单信息</h4>
    </div>
    <div style="margin-top: 20px">
      <div class="jbBox" style="min-width: 200px">
        <span>订单编号：</span>
        {{ info.order_sn }}
      </div>
      <div class="jbBox" style="min-width: 200px">
        <span>下单日期：</span>
        {{ info.order_at }}
      </div>
    </div>
    <!--    药品列表-->
    <div class="order-list">
      <div v-for="item in goods" :key="item.id" class="order-item">
        <img :src="item.image_url"/>
        <div>
          <div><span>{{ item.goods_name }}</span>（{{ item.norm }}）</div>
          <div>购买数量：{{ item.number }}{{ item.unit }}</div>
          <div style="color:#DE241B;">购买金额：{{ item.total_price }}元</div>
        </div>
      </div>
    </div>
    <!--    药品订单合计-->
    <div class="order-money">
      合计：{{info.goods_number}}{{info.goods_unit}} <span style="margin-left: 12px">合计金额：{{info.order_price}}元</span>
    </div>
    <div class="titleHead" style="margin-top: 20px">
      <span></span>
      <h4>物流信息</h4>
    </div>
    <div style="margin-top: 20px">
      <div class="jbBox" style="min-width: 200px">
        <span>收货人：</span>
        {{ stock_send.receive_name }}
      </div>
      <div class="jbBox" style="min-width: 200px">
        <span>联系方式：</span>
        {{ stock_send.receive_tel }}
      </div>
      <div class="jbBox" style="min-width: 200px">
        <span>收货地址：</span>
        {{ stock_send.receive_address }}
      </div>
    </div>

    <div class="titleHead" style="margin-top: 20px">
      <span></span>
      <h4>购买者信息</h4>
    </div>
    <div style="margin-top: 20px">
      <div class="jbBox" style="min-width: 200px">
        <span>姓名：</span>
        {{ info.buyer_name }}
      </div>
      <div class="jbBox" style="min-width: 200px">
        <span>年龄：</span>
        {{ buyer_age }}
      </div>
      <div class="jbBox" style="min-width: 200px">
        <span>联系方式：</span>
        {{ info.buyer_phone }}
      </div>
    </div>


    <div class="titleHead" style="margin-top: 20px">
      <span></span>
      <h4>处方信息</h4>
    </div>
    <div style="margin-top: 20px">
      <div class="jbBox" style="min-width: 200px">
        <span>处方号：</span>
        {{ recipe.recipe_sn }}
      </div>
      <div class="jbBox" style="min-width: 200px">
        <span>开方日期：</span>
        {{ recipe.over_at }}
      </div>
    </div>
    <div>
      <div class="jbBox" style="min-width: 200px">
        <span>开方医师：</span>
        {{ recipe.doctor_name }}
      </div>
      <div class="jbBox" style="min-width: 200px">
        <span>开方科室：</span>
        {{ recipe.dept_name }}
      </div>
      <div class="jbBox" style="min-width: 200px">
        <span>审方医师：</span>
        {{ recipe.audit_doctor }}
      </div>
      <div class="jbBox" style="min-width: 200px">
        <span>审方药师：</span>
        {{ recipe.audit_pharm }}
      </div>
    </div>
    <div>
      <div class="jbBox">
        <span>初步诊断：</span>
        {{ recipe.first_diagnose }}
      </div>
    </div>
    <div>
      <div class="jbBox">
        <span>诊断结果：</span>
        {{ recipe.diagnose_result }}
      </div>
    </div>
    <div style="margin-top: 12px">
      <el-table :data="recipe.goods" stripe style="width: 910px">
        <el-table-column label="药品名称" prop="goods_name"></el-table-column>
        <el-table-column label="数量" prop="number">
        </el-table-column>
        <el-table-column label="药品用法" prop="using">
        </el-table-column>
        <el-table-column label="使用频次" prop="frequency">
        </el-table-column>
        <el-table-column label="每次剂量" prop="dose">
        </el-table-column>
        <el-table-column label="用药天数" prop="days">
        </el-table-column>
      </el-table>
    </div>
    <!--    <div style="display: flex; justify-content: center; margin-top: 20px">-->
    <!--&lt;!&ndash;      <el-button size="small">取消</el-button>&ndash;&gt;-->
    <!--&lt;!&ndash;      <el-button size="small" type="primary">重置</el-button>&ndash;&gt;-->
    <!--    </div>-->
    <!--    <div style="display: flex; justify-content: center; margin-top: 20px">-->
    <!--      <el-button size="small" type="primary">打印处方单</el-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {getRecipeInfo} from "@/api/share/share";

export default {
  name: "shareDetail",
  data() {
    return {
      id: "",
      info: "",
      buyer_age_arr: [
        {
          value: 1,
          text: "0-18岁"
        },
        {
          value: 2,
          text: "19-35岁"
        },
        {
          value: 3,
          text: "36-59岁"
        }, {
          value: 4,
          text: "60岁以上"
        }
      ]
    }
  },
  computed: {
    goods() {
      return this.info.goods || []
    },
    stock_send() {
      return this.info.stock_send || {}
    },
    recipe() {
      return this.info.recipe || {}
    },
    buyer_age() {
      try {
        return this.buyer_age_arr[this.info.buyer_age - 1].text
      } catch (e) {
        return "未知"
      }
    }
  },
  methods: {
    // 获取处方详情
    async getRecipeInfo() {
      const {code, data} = await getRecipeInfo({
        recipe_id: this.id
      })
      if (code !== 200) return
      this.info = data
    }
  },
  created() {
    this.id = this.$route?.query?.id
    if (!this.id) {
      this.$router.back()
      return
    }
    this.getRecipeInfo()
  }
}
</script>

<style lang="scss" scoped>
.shareDetail {
  padding: 16px;
  box-sizing: border-box;
  height: calc(100vh - 144px);
  overflow: auto;
  margin: auto;
}

.titleHead {
  span {
    width: 7px;
    height: 20px;
    display: inline-block;
    background-color: #2a2f63;
    vertical-align: middle;
    margin-right: 8px;
  }

  h4 {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
  }
}

.jbBox {
  display: inline-block;
  font-size: 14px;
  color: #394359;
  margin-right: 16px;
  vertical-align: top;
  padding: 8px 0;
  box-sizing: border-box;

  span {
    display: inline-block;
    color: #6a7387;
  }

  p {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 6px;
  }
}

.order-list {
  display: flex;
  flex-wrap: wrap;
}

.order-item {
  width: 380px;
  height: 80px;
  margin-top: 20px;
  margin-right: 20px;
  display: flex;

  > img {
    width: 100px;
    height: 100%;
    //background-color: #795da3;
    margin-right: 12px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;
    line-height: 16px;
    padding: 2px 0;
    color: #6A7387;

    span {
      font-weight: bold;
      color: #394359;
    }
  }
}

.order-money {
  height: 50px;
  background-color: #EBEEF8;
  margin-top: 20px;
  line-height: 50px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC, serif;
  font-weight: bold;
  color: #394359;
}
</style>
