import req from "@/utils/http";

let url = ""
if (window.location.host === "net-admin.mhzzr.com") {
    url = "https://share-api.mhzzr.com"
} else {
    url = "http://dev-share-api.mhzzr.com"
}

export const getRecipeList = params => req("get", url + "/doctor/recipe/index", params)

export const submitRecipe = params => req("post", url + "/doctor/recipe/submit", params)

export const getRecipeInfo = params => req("get", url + "/doctor/recipe/info", params)
